import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const FoodelWebsitePage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Foodel",
    location: "Jacksonville, FL",
    industry: "Food Delivery",
    developmentType: "Website",
    scope: "Design and Development",
    businessObjectives:
      "To become the go to healthy meal subscription delivery service in Jacksonville and generate $100K in sales within their first year of business. This will ideally require 20,000 downloads.",
    desiredOutcomes:
      "To have an online presence website that directs to their mobile application developed by the Tactic Apps team. The website needs to convert visitors into 'app downloaders' at 2.5%. The website also needs to provide a form to contact the company with inquiries.",
    targetMarket: "Busy Professionals",
    competition: "Fresh n' Lean",
    competitiveAdvantage:
      "Food is delivered hot and fresh vs. prepackaged and requiring reheating.",
    timeFrame: "2 Months",
  }

  const userStories = {
    mustHaves: [
      "Attractive header to draw visitors into the page",
      "A feature section highlighting the benefits of subscribing",
      "A food gallery to display popular meals",
      "A simple instruction section showing visitors where to download the app",
      "A location section to show the currently serviced cities",
      "A pricing section to show the different subscription packages and their benefits",
      "Pricing section redirects visitors to the app download",
      "A contact section so visitors can provide feedback",
      "A testimonials section to showcase what customers are saying about the service",
      "Uniform color scheme and branding between the app and the website",
    ],
    niceToHaves: [
      "Ability to sign up on the website",
      "Chat bot integration with Zendesk to provide customer support",
      "Order tracking through Google Maps by entering order info",
      "Social media links",
      "Blog integration",
      "Newsletter signup to showcase newest meals",
    ],
    maybeLater: [
      "Visitors have the ability to manage their orders and accounts on the website",
      "An admin panel to manage app users",
      "Converting the website to a web app and allowing users to order online",
      "Add an AI bot to support with frequently asked questions",
      "Delivery driver login to show their delivery route",
      "Twitter integration to showcase latest tweets and customer experiences",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Color scheme selection based on web app",
        "Header Design and Development",
        "Navigation Design and Development",
      ],
      weeks: "Week 2",
    },
    iteration3: {
      stories: [
        "Feature section design and development",
        "Gallery section design and development",
      ],
      weeks: "Week 3",
    },
    iteration4: {
      stories: [
        "Download section design and development",
        "Locations section design and development",
      ],
      weeks: "Week 4",
    },
    iteration5: {
      stories: [
        "Testimonials section design and development",
        "Pricing section design and development",
      ],
      weeks: "Week 5",
    },
    iteration6: {
      stories: [
        "Contact section design and development",
        "Development for the contact form backend support",
      ],
      weeks: "Week 6",
    },
    iteration7: {
      stories: [
        "Footer section design and development with Social Media Links",
        "User acceptance testing",
        "Minor bug fixes before release",
      ],
      weeks: "Week 7",
    },
    iteration8: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 8",
    },
  }

  const technologies = {
    fullStack: false,
    icons: ["HTML5", "CSS3", "JavaScript", "React", "Jest", "Git"],
  }

  const challenges = {
    challenge1: {
      challenge:
        "Foodel had a list of user stories that, when iterated, exceeded their development deadline.",
      win: (
        <>
          <p>
            The Foodel team had a moderately sized list of user stories but many
            of them were large and could not fit into their 2 month deadline.
          </p>
          <p>
            Using an agile approach we prioritized, sized and iterated their
            stories to provide them with a website that provided the core
            functionality they needed to promote their mobile application.
          </p>
          <p className="mb-0">
            They now have the option to integrate the remaining user stories for
            their next release.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Foodel considered converting their presence website into a web application to enable ordering.",
      win: (
        <>
          <p>
            Instead of simply promoting their mobile application, the Foodel
            team considered building a web application to mimic their mobile
            app.
          </p>
          <p>
            The Tactic Apps team showed Foodel that this wouldn't be possible in
            the 2 month time frame by iterating through the web application.
          </p>
          <p className="mb-0">
            It was decided that promoting their mobile application would remain
            their main goal in the first iteration of their website.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "Not all digital assets were provided at the beginning of the project.",
      win: (
        <>
          <p>
            Foodel had a small team of people with many responsibilities.
            Unfortunately they were unable to deliver all the high resolution
            images required for all the sections of the website due to other
            commitments.
          </p>
          <p className="mb-0">
            Instead of pausing the project, the Tactic Apps design team proposed
            using some high quality stock images that fit well with the design.
            These were accepted and allowed the project to remain on track.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "Due to much back and forth on user story priorities, selecting a development technology was challenging.",
      win: (
        <>
          <p>
            For general website design and development, the Tactic Apps team
            will use a static site generator like Gatsby. However, due to the
            potential of converting this website into a web application on the
            second release, we decided to work with React.
          </p>
          <p className="mb-0">
            Using React will save the Foodel Team time and money when it comes
            time to allow users to order online.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "The Google Maps API integration stopped working on release day",
      win: (
        <>
          <p>
            On release day the Contact Section on the website showed a big
            Google Maps error. Tactic Apps provides emergency support so we
            immediately went to work to locate the underlying cause.
          </p>
          <p className="mb-0">
            After about and hour we discovered that the credit card on file with
            Google had expired and needed to be updated. We had the website back
            to full operation within 70 minutes.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "1",
      hourly: "65",
      time: "2",
    },
  }

  const resultsImageNames = {
    results1: "Header",
    results2: "Features & Gallery",
    results3: "Download",
    results4: "Locations",
    results5: "Testimonials",
    results6: "Pricing",
    results7: "Contact & Footer",
  }

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: { eq: "work/websites/foodel/foodel-hero.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: { eq: "work/websites/foodel/devices.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: { eq: "work/websites/foodel/wireframes/heading.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: { eq: "work/websites/foodel/wireframes/features.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: { eq: "work/websites/foodel/wireframes/gallery.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: { eq: "work/websites/foodel/wireframes/download.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: { eq: "work/websites/foodel/wireframes/cities.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/websites/foodel/wireframes/testimonials.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: { eq: "work/websites/foodel/wireframes/pricing.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe8: file(
            relativePath: { eq: "work/websites/foodel/wireframes/contact.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-header.jpeg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-features.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-download.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-cities.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-testimonials.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-pricing.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: {
              eq: "work/websites/foodel/foodel-results-contact-footer.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Foodel Website Case Study"
            keywords={[`foodel website case study`]}
            description="The Foodel website is a landing page for their premium healthy food delivery mobile app. The design is 100% custom CSS with no frameworks."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Website Case study"
            subTitle="Foodel"
            pageContext={pageContext}
            location={location}
            crumbLabel="Foodel"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default FoodelWebsitePage
